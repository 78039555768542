import {
  AfterContentInit,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { BrandingService } from 'tt-branding-lib';
import { CommonService } from 'tt-common-lib';
import { ShoppingCartService } from 'tt-web-store';
import { UserProduct } from 'tt-web-store/lib/models/user.product.model';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, AfterContentInit {
  title: string = 'Application';
  customerSupport: string;

  @ViewChild('sidenav') sidenav?: MatSidenav;

  _user: BehaviorSubject<any> = new BehaviorSubject({});
  readonly user$?: Observable<any>;
  user: any;

  config: any;
  isHandset$?: Observable<boolean>;
  cartProductCount: number = 0;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: FirebaseAuthService,
    private branding: BrandingService,
    private titleService: Title,
    private router: Router,
    private commonService: CommonService,

    private shoppingCartService: ShoppingCartService
  ) {
    this.user$ = this.authService.currentuser$;
    this.authService.currentuser$.subscribe((user: any) => (this.user = user));
    this.isHandset$ = this.commonService.isHandset$;
  }

  ngAfterContentInit(): void {
    this.calulateSize();
  }
  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.branding.config$.subscribe((data) => {
      this.config = data;
      this.title = this.config?.app?.title;
      this.customerSupport = this.config?.app?.support;
      this.titleService.setTitle(this.config?.app?.title);
    });
    this.shoppingCartService.storeProducts$.subscribe((data: UserProduct[]) => {
      this.cartProductCount = this.shoppingCartService.getTotalItems(
        data.filter((x) => x.orderQty && x.orderQty > 0)
      );
    });
  }

  closeSideNav(force: boolean) {
    if (Boolean(force) || this.sidenav?.mode === 'over') {
      this.sidenav?.close();
    }
  }
  onLogout(user: any) {
    this.authService.logout();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.calulateSize();
  }

  calulateSize() {
    let vh = (window.innerHeight > 3840 ? 3840 : window.innerHeight) * 0.01;
    this.document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}
