<div *ngIf="!(isHandset$ | async)">
  <tt-footer></tt-footer>
</div>

<mat-toolbar class="bg-primary-light" *ngIf="isHandset$ | async">
  <div class="spacer"></div>
  <button mat-icon-button color="primary" routerLink="/store/offers">
    <mat-icon>local_offer </mat-icon>
  </button>
  <div class="spacer"></div>

  <button mat-icon-button color="primary" routerLink="/store/shopping-cart">
    <mat-icon>shopping_cart </mat-icon>
  </button>
  <div class="spacer"></div>

  <button mat-fab color="primary" (click)="openBottomSheet()">
    <mat-icon>add_box </mat-icon>
  </button>
  <div class="spacer"></div>

  <button mat-icon-button color="primary" routerLink="/store/support">
    <mat-icon>support_agent </mat-icon>
  </button>
  <div class="spacer"></div>

  <button mat-icon-button color="primary" routerLink="/store/order/list">
    <mat-icon>reorder </mat-icon>
  </button>
  <div class="spacer"></div>
</mat-toolbar>
