export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAmXniS63VGPQr-QhmBO1ZCJegU39yW374",
    authDomain: "spiderbooks-dev.firebaseapp.com",
    projectId: "spiderbooks-dev",
    storageBucket: "spiderbooks-dev.appspot.com",
    messagingSenderId: "179250258952",
    appId: "1:179250258952:web:3cb3ec841f4cb6de439dbd",
    measurementId: "G-T42XS4YLKF",
  },
};
