<div class="container" *ngIf="showErrorMessage">
  <mat-card>
    <mat-card-content>
      <div class="alert alert-danger" role="alert">
        <div class="text-center">
          <span style="font-size: 10em">
            <mat-icon>person</mat-icon>
          </span>
          <h1>Registration Failed</h1>
          <div>
            Account already exists!. If you forget your username or password,
            use the below link to reset
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="container" *ngIf="confirmationMessage">
  <mat-card>
    <mat-card-content>
      <div class="alert alert-info" role="alert">
        <div class="text-center">
          <span style="font-size: 10em">
            <mat-icon>how_to_reg</mat-icon>
          </span>
          <h1>Thanks for your Registration</h1>
          <div>
            <div>
              We have send you an confirmation email to <b>{{ model.email }}</b>
            </div>
            <div>
              Please confirm your email address to activate your account
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="container" *ngIf="!(confirmationMessage || showErrorMessage)">
  <tt-custom-multi-form
    [formId$]="formId$"
    [user]="user"
    [model$]="model$"
    (submitAction)="submit($event)"
  >
  </tt-custom-multi-form>
  <div class="container">
    <div class="w-100 text-end mb-3">
      <button (click)="willdoitlater()" color="accent" mat-flat-button>
        Will do it later
      </button>
    </div>
  </div>
</div>
