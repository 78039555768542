import { ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { Observable } from 'rxjs';
import { BrandingService } from 'tt-branding-lib';
import { CommonService } from 'tt-common-lib';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  isHandset$: Observable<boolean>;
  showTapToTop: boolean = false;
  copyright: string;
  config: any;
  year: number;
  constructor(
    private commonService: CommonService,
    private viewScroller: ViewportScroller,
    private _bottomSheet: MatBottomSheet,
    private branding: BrandingService
  ) {
    this.isHandset$ = this.commonService.isHandset$;
    this.branding.config$.subscribe((data) => {
      this.config = data;
      this.copyright = data?.legal?.copyright;
      this.year = new Date().getFullYear();
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number > 100) {
      this.showTapToTop = true;
    } else {
      this.showTapToTop = false;
    }
  }

  tapToTop() {
    this.viewScroller.scrollToPosition([0, 0]);
  }

  openBottomSheet(): void {
    this._bottomSheet.open(MobileBottomMenu);
  }

  ngOnInit(): void {}
}

@Component({
  selector: 'mobile-bottom-menu',
  templateUrl: 'mobile-bottom-menu.html',
})
export class MobileBottomMenu {
  constructor(private _bottomSheetRef: MatBottomSheetRef<MobileBottomMenu>) {}
  closeMenu(event: MouseEvent): void {
    this._bottomSheetRef.dismiss();
    event.preventDefault();
  }
}
