<div class="main-container">
  <!-- Header-->
  <div class="header">
    <mat-toolbar color="primary" style="color: white !important">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon *ngIf="!sidenav?.opened">menu</mat-icon>
        <mat-icon *ngIf="sidenav?.opened">menu_open</mat-icon>
      </button>
      <div class="ml-1">
        <div>{{ title }}</div>
      </div>
      <span class="spacer"></span>

      <button
        style="margin-right: 0.1 rem"
        mat-button
        routerLink="/store/support"
      >
        <div class="mat-icon-text">
          <mat-icon>whatsapp</mat-icon>
          <span *ngIf="!(isHandset$ | async)">{{ customerSupport }} </span>
        </div>
      </button>

      <button mat-icon-button routerLink="/store/shopping-cart">
        <mat-icon
          class="mr-2"
          [matBadge]="cartProductCount"
          matBadgeColor="accent"
          >shopping_cart
        </mat-icon>
      </button>
    </mat-toolbar>
  </div>

  <div class="app-content">
    <mat-sidenav-container fullscreen>
      <mat-sidenav
        fixedInViewport="true"
        class="app-sidenav"
        #sidenav
        fixedTopGap="56"
        fixedBottomGap="56"
        [opened]="!(isHandset$ | async)"
        [mode]="(isHandset$ | async) ? 'over' : 'side'"
        style="width: 15em"
      >
        <tt-left-menu
          [user$]="user$"
          (logout)="onLogout($event)"
          (closeSideNav)="closeSideNav($event)"
        >
        </tt-left-menu>
      </mat-sidenav>
      <mat-sidenav-content>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <div class="footer bg-primary-light" style="height: 56px !important">
    <app-footer></app-footer>
  </div>
</div>
