import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviorSubject } from 'rxjs';
import { FirebaseAuthService } from 'tt-authentication-lib';

export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}

@Component({
  selector: 'app-unregistered',
  templateUrl: './unregistered.component.html',
  styleUrls: ['./unregistered.component.scss'],
})
export class UnregisteredComponent implements OnInit {
  model: any = {};
  confirmationMessage: boolean = false;
  showErrorMessage: boolean = false;
  user: any;

  _formId: BehaviorSubject<string> = new BehaviorSubject('registration');
  formId$ = this._formId.asObservable();

  _model: BehaviorSubject<any> = new BehaviorSubject({});
  model$ = this._model.asObservable();

  constructor(
    private authService: FirebaseAuthService,
    private datepipe: DatePipe,
    private router: Router
  ) {}

  submit(model: any) {
    let formData = { ...this.model, ...model };
    formData['emailVerified'] = this.user?.emailVerified;
    formData['upsinterstitial'] = true;
    formData['upsexpiry'] = 0;
    formData['instagramconsent'] = this.datepipe.transform(
      new Date(),
      'medium'
    );
    formData['photoURL'] = this.user?.photoURL;
    this.authService.updateuserPartial(formData).then((response: any) => {
      if (response) {
        this.confirmationMessage = true;
        this.showErrorMessage = false;
      } else {
        this.confirmationMessage = false;
        this.showErrorMessage = true;
      }
    });
  }

  willdoitlater() {
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + 7);
    let formData = {
      uid: this.user.uid,
      upsinterstitial: true,
      upsexpiry: '' + todayDate.getTime(),
    };
    this.authService.updateuserPartial(formData).then((response: any) => {
      this.router.navigateByUrl('/home');
    });
  }

  ngOnInit(): void {
    this.authService.currentuser$.subscribe((data: any) => {
      if (data?.uid) {
        this.user = data;
        this.model['id'] = data.uid;
        this.model['name'] = data.displayName;
        this.model['uid'] = data.uid;
        this.model['email'] = data.email;
        this._model.next(this.model);
      } else {
        if (Boolean(data.authorized)) this.router.navigateByUrl('/home');
        else this.router.navigateByUrl('/login');
      }
    });
  }
}
