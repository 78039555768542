import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LoginComponent,
  ProfileComponent,
  UnauthorizedComponent,
} from 'tt-authentication-lib';
import { UserAuthGuard } from './authentication/guards/user-auth.guard';
import { UnregisteredComponent } from './pages/unregistered/unregistered.component';
import {
  PrivacyPolicyComponent,
  RefundPolicyComponent,
  TermsConditionComponent,
  TtWebStoreModule,
  WebStoreComponent,
} from 'tt-web-store';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'unregistered', component: UnregisteredComponent },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent },
  {
    path: 'user/profile',
    component: ProfileComponent,
    canActivate: [UserAuthGuard],
  },
  { path: 'store/terms', component: PrivacyPolicyComponent },
  { path: 'store/privacy', component: TermsConditionComponent },
  { path: 'store/refund', component: RefundPolicyComponent },
  {
    path: 'store',
    component: WebStoreComponent,
    canActivate: [UserAuthGuard],
    canActivateChild: [UserAuthGuard],
    data: { mode: 'user' },
    loadChildren: () => TtWebStoreModule,
  },
  { path: '**', component: LoginComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
