import { Injectable } from '@angular/core';
import { switchMap, catchError, take } from 'rxjs/operators';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { FirebaseAuthService } from 'tt-authentication-lib';
import { User } from 'tt-authentication-lib/lib/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private authService: FirebaseAuthService
  ) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.authService.currentuser$.pipe(
      take(1),
      switchMap((user: any) => {
        return of(this.guardRequest(user, state));
      }),
      catchError(() => {
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      })
    );
  }

  updateProfileRequired(user: any) {
    if (user.authorized && !user.instagramconsent) {
      let unregisteredpageshown = Boolean(user.upsinterstitial || 'false');
      let upsexpiry = Number(user.upsexpiry || 0);
      if (
        !unregisteredpageshown ||
        (unregisteredpageshown && new Date().getTime() >= upsexpiry)
      ) {
        return true;
      }
    }
    return false;
  }

  guardRequest(user: User, state: RouterStateSnapshot) {
    if (user?.uid) {
      if (this.updateProfileRequired(user)) {
        this.router.navigateByUrl('/unregistered');
      }

      if (Boolean(user.unregistered)) {
        this.router.navigateByUrl('/unregistered');
        return false;
      }

      if (Boolean(user.authorized)) {
        return true;
      } else {
        this.router.navigateByUrl('/unauthorized');
        return false;
      }
    } else {
      this.router.navigate(['login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.currentuser$.pipe(
      take(1),
      switchMap((user: User) => {
        return of(this.guardRequest(user, state));
      }),
      catchError(() => {
        this.router.navigate(['login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      })
    );
  }
}
