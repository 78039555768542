<div style="position: absolute; top: 0px; right: 0px">
  <button mat-icon-button color="primary" (click)="closeMenu($event)">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-nav-list>
  <a routerLink="/store/order/list" mat-list-item (click)="closeMenu($event)">
    <div style="display: flex; flex-direction: row">
      <div style="margin: auto">
        <mat-icon>local_shipping</mat-icon>
      </div>
      <div>
        <span mat-line>Order Status</span>
        <span mat-line>Track your orders online</span>
      </div>
    </div>
  </a>

  <a routerLink="/store/support" mat-list-item (click)="closeMenu($event)">
    <div style="display: flex; flex-direction: row">
      <div style="margin: auto">
        <mat-icon>support_agent</mat-icon>
      </div>
      <div>
        <span mat-line>Customer Support</span>
        <span mat-line>Raise your queries and questions for better support</span>
      </div>
    </div>
  </a>
  <a
    routerLink="/store/product/c/Preschool"
    mat-list-item
    (click)="closeMenu($event)"
  >
    <div style="display: flex; flex-direction: row">
      <div style="margin: auto">
        <mat-icon>shopping_cart_checkout</mat-icon>
      </div>
      <div>
        <span mat-line>Shop Now</span>
        <span mat-line>One Shop for large variety of Children Books</span>
      </div>
    </div>
  </a>
</mat-nav-list>
