import { Component, OnInit } from '@angular/core';
import { BrandingService } from 'tt-branding-lib';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  config: any;

  images = [];
  displayTypes = [];

  constructor(private branding: BrandingService) {}

  ngOnInit(): void {
    this.branding.config$.subscribe((data) => {
      this.config = data;
      this.images = this.config.module.common.feature.images;
      this.displayTypes = this.config.module.common.feature.types;
    });
  }
}
