import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import {
  DocumentAssetPicker,
  ImageAssetPicker,
  TextEditor,
  TtCommonLibModule,
} from 'tt-common-lib';
import { TtAuthenticationLibModule } from 'tt-authentication-lib';
import {
  BrandingService,
  ThemingService,
  TtBrandingLibModule,
} from 'tt-branding-lib';
import { TtPageComponentsLibModule } from 'tt-page-components-lib';
import { TtWebStoreModule } from 'tt-web-store';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { firebase, firebaseui, FirebaseUIModule } from 'firebaseui-angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { CommonModule, DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { HttpClientModule } from '@angular/common/http';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from '@angular/fire/compat/performance';

import { FormlyMaterialModule } from '@ngx-formly/material';
import {
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
} from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxImageCompressService } from 'ngx-image-compress';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { UserAuthGuard } from './authentication/guards/user-auth.guard';
import { UnregisteredComponent } from './pages/unregistered/unregistered.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
  FooterComponent,
  MobileBottomMenu,
} from './components/footer/footer.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { HomeComponent } from './pages/home/home.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    { provider: firebase.auth.GoogleAuthProvider.GOOGLE_SIGN_IN_METHOD },
  ],
  tosUrl: '/store/terms',
  privacyPolicyUrl: '/store/privacy',
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
};

export function MobileValidator(control: FormControl): ValidationErrors {
  return !control.value || /^(\+\d{1,3}[- ]?)?\d{10}$/.test(control.value)
    ? null
    : { mobile: true };
}

export function MobileValidatorMessage(err, field: FormlyFieldConfig) {
  return `This is not a valid mobile`;
}

export function EmailValidator(control: FormControl): ValidationErrors {
  return !control.value ||
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      control.value
    )
    ? null
    : { email: true };
}

export function EmailValidatorMessage(err, field: FormlyFieldConfig) {
  return `This is not a valid email address`;
}

export function minlengthValidationMessage(err: any, field: any) {
  return `Should have atleast ${field.templateOptions.minLength} characters`;
}

export function maxlengthValidationMessage(err: any, field: any) {
  return `This value should be less than ${field.templateOptions.maxLength} characters`;
}

export function minValidationMessage(err: any, field: any) {
  return `This value should be more than ${field.templateOptions.min}`;
}

export function maxValidationMessage(err: any, field: any) {
  return `This value should be less than ${field.templateOptions.max}`;
}

@NgModule({
  declarations: [
    AppComponent,
    UnregisteredComponent,
    FooterComponent,
    HomeComponent,
    MobileBottomMenu,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    TtCommonLibModule,
    TtWebStoreModule,
    TtAuthenticationLibModule,
    TtBrandingLibModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCardModule,
    MatIconModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSidenavModule,
    MatBadgeModule,
    MatButtonModule,
    ScrollingModule,
    MatListModule,
    TtCommonLibModule,
    MatBottomSheetModule,
    TtPageComponentsLibModule,
    FormlyModule.forRoot({
      types: [
        { name: 'docassetpicker', component: DocumentAssetPicker },
        { name: 'assetpicker', component: ImageAssetPicker },
        { name: 'texteditor', component: TextEditor },
      ],
      extras: { lazyRender: true },
      validators: [
        { name: 'email', validation: EmailValidator },
        { name: 'mobile', validation: MobileValidator },
      ],
      validationMessages: [
        { name: 'email', message: EmailValidatorMessage },
        { name: 'mobile', message: MobileValidatorMessage },
        { name: 'required', message: 'This field is required' },
        { name: 'minlength', message: minlengthValidationMessage },
        { name: 'maxlength', message: maxlengthValidationMessage },
        { name: 'min', message: minValidationMessage },
        { name: 'max', message: maxValidationMessage },
      ],
    }),
    FormlyMaterialModule,
    MatNativeDateModule,
    IvyCarouselModule,
    NgxPageScrollCoreModule,
  ],
  providers: [
    BrandingService,
    DatePipe,
    UserAuthGuard,
    Title,
    NgxImageCompressService,
    ScreenTrackingService,
    UserTrackingService,
    PerformanceMonitoringService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: BrandingService) => () => config.load(),
      deps: [BrandingService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (themingService: ThemingService) => () =>
        themingService.initialize(),
      deps: [ThemingService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (themingService: ThemingService) => () =>
        themingService.initialize(),
      deps: [ThemingService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
