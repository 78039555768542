<tt-carousel [images]="images"></tt-carousel>

<tt-page pageId="home"></tt-page>

<div *ngFor="let item of displayTypes">
  <tt-featured-product
    [height]="item?.height"
    [cellWidth]="item?.cellWidth"
    [autoplay]="item?.autoplay"
    [type]="item.type"
    [title]="item.title"
  ></tt-featured-product>
</div>
